/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  type MantineColorScheme,
  type CSSVariablesResolver,
  createTheme,
  rem,
  MantineColorSchemeManager,
  PasswordInput,
  TextInput,
  NumberInput,
  em,
  NativeSelect,
  Autocomplete,
  Textarea,
  alpha,
  lighten,
  MultiSelect,
} from "@mantine/core";
import { DatePickerInput, TimeInput } from "@mantine/dates";
import { themeToVars } from "@mantine/vanilla-extract";
import Cookies from "js-cookie";

// Mobile safari styles!!

export const cssVariablesResolver: CSSVariablesResolver = (theme) => ({
  variables: {
    "--mantine-color-body": theme.colors.snDarkBackground01[0],
    "--mantine-color-error": vars.colors.red[5],
    "--scuba-header-height": theme.other.headerHeight,
    "--scuba-app-header-z-index": theme.other.zIndex.appHeader,
    "--scuba-blue": theme.colors.snLightBlue03[0],
    "--scuba-light-blue": theme.colors.snLightBackground01[0],
  },
  light: {
    "--mantine-color-body": theme.colors.snLightBackground01[0],
    "--mantine-color-error": vars.colors.red[8],
    "--text-color": "#0D1526",
  },
  dark: {
    "--mantine-color-body": theme.colors.snDarkBackground01[0],
    "--mantine-color-error": vars.colors.red[5],
    "--text-color": "#FFFFFF",
  },
});

export const theme = createTheme({
  fontFamily: "Rubik, Roboto, sans-serif",

  colors: {
    // Add your color
    // or replace default theme color

    dark: [
      "#DDF3F2",
      "#BBE7E8",
      "#98D5DC",
      "#76C0D0",
      "#54A6C4",
      "#3C87AF",
      "#30658D",
      "#24466A",
      "#192C48",
      "#0D1526",
    ],

    oceanGreen: ["#CFDEE5", "#1B3A47"] as any,

    black: ["#000000"] as any,
    white: ["#FFFFFF"] as any,
    snBlack10: [alpha("#000000", 0.1)] as any,
    snWhite10: [alpha("#FFFFFF", 0.1)] as any,
    snWhite15: [alpha("#FFFFFF", 0.1)] as any,
    snBlack20: [alpha("#000000", 0.2)] as any,
    snWhite20: [alpha("#FFFFFF", 0.2)] as any,
    snBlack40: [alpha("#000000", 0.4)] as any,
    snWhite40: [alpha("#FFFFFF", 0.4)] as any,
    snBlack60: [alpha("#000000", 0.6)] as any,
    snWhite60: [alpha("#FFFFFF", 0.6)] as any,
    snBlack80: [alpha("#000000", 0.8)] as any,
    snWhite80: [alpha("#FFFFFF", 0.8)] as any,
    snDarkBlue01: ["#0D1526"] as any,
    snLightBlue01: ["#E1F1FF"] as any,
    snDarkBlue02: ["#1D293A"] as any,
    snLightBlue02: ["#B5DFFF", alpha("#B5DFFF", 0.6)] as any,
    snDarkBlue03: ["#0041F6"] as any,
    snLightBlue03: ["#0041F6"] as any,
    snDarkBlue04: ["#58B9FC"] as any,
    snLightBlue04: ["#58B9FC"] as any,
    snDarkPurple01: ["#8BA8F5"] as any,
    snLightPurple01: ["#8BA8F5"] as any,
    snDarkPurple40: [alpha("#32327B", 0.4)] as any,
    snDarkPurple41: [lighten("#1B1C4B", 0.1)] as any,
    snLightPurple40: ["#FFFFFF"] as any,
    snDarkLime01: ["#D8F715"] as any,
    snLightLime01: ["#CEED08"] as any,
    snDarkGrey01: ["#989EBD"] as any,
    snLightGrey01: ["#999999"] as any,
    snDarkText01: ["#FFFFFF"] as any,
    snLightText01: ["#0D1526"] as any,
    snDarkBackground01: ["#060622"] as any,
    snLightBackground01: ["#BFD9EE"] as any,
  },

  shadows: {
    md: "1px 1px 3px rgba(0, 0, 0, .25)",
    xl: "5px 5px 3px rgba(0, 0, 0, .25)",
  },

  headings: {
    fontWeight: "500",
    sizes: {
      h1: { fontSize: rem(34) },
      h2: { fontSize: rem(28) },
      h3: { fontSize: rem(20) },
    },
  },

  fontSizes: {
    xxl: rem(30),
  },

  cursorType: "pointer",

  breakpoints: {
    xs: em(450),
  },

  spacing: {
    xxs: em(4),
    xs: em(8),
  },

  components: {
    ActionIcon: {
      styles: {
        root: {
          background: "transparent",
        },
      },
    },

    TextInput: TextInput.extend({
      styles: {
        label: {
          fontSize: 12,
        },
      },
    }),

    PasswordInput: PasswordInput.extend({
      styles: {
        label: {
          fontSize: 12,
        },
      },
    }),

    DatePickerInput: DatePickerInput.extend({
      styles: {
        label: {
          fontSize: 12,
        },
      },
    }),

    NumberInput: NumberInput.extend({
      styles: {
        label: {
          fontSize: 12,
        },
      },
    }),

    NativeSelect: NativeSelect.extend({
      styles: {
        label: {
          fontSize: 12,
        },
      },
    }),

    Autocomplete: Autocomplete.extend({
      styles: {
        label: {
          fontSize: 12,
        },
      },
    }),

    TimeInput: TimeInput.extend({
      styles: {
        label: {
          fontSize: 12,
        },
      },
    }),

    Textarea: Textarea.extend({
      styles: {
        label: {
          fontSize: 12,
        },
      },
    }),

    MultiSelect: MultiSelect.extend({
      styles: {
        label: {
          fontSize: 12,
        },
      },
    }),
  },

  other: {
    headerHeight: "70px",
    zIndex: {
      appHeader: 10,
    },
  },
});

export const vars = themeToVars(theme);

export function cookiesColorSchemeManager({
  key,
}: {
  key: string;
}): MantineColorSchemeManager {
  return {
    get: (defaultValue) => {
      return (Cookies.get(key) as MantineColorScheme) || defaultValue;
    },

    set: (value) => {
      Cookies.set(key, value);
    },

    subscribe: () => {},
    unsubscribe: () => {},
    clear: () => {},
  };
}
